import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '../src/custom_antd.css'
import '../../../node_modules/jquery-ui-dist/jquery-ui.css'
import '../app/menu'
import '../app/user'
import '../src/main.scss'
import '../src/login.scss'
import '../app'
import '../app/recipe'
import '../app/food';
import '../app/add_food'
import '../app/add_recipe'
import '../app/shopping_list'
import '../app/subscription'
//= require react
import './server_rendering'
require('jquery')
require('jquery-ui')
require("@nathanvda/cocoon")
//= require cocoon
