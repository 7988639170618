require('jquery');
import Rails from 'rails-ujs';

$(document).ready(function() {

    $(".change_setting_btn").on('click',function() {
        let form = document.getElementById("new_food");
        if(window.location.pathname.includes("edit")) {
            form = document.getElementsByClassName("food")[0];
        }

       let submit = true;
       let name = document.getElementById("food_name");
       let select =  document.getElementById("food_food_groups_attributes_0_group_id");
       if (select.value == ""){
           submit = false;
           show_error(select, "Оберіть категорію");
       }
        if(name.value == ""){
            submit = false;
            show_error(name, "Вкажіть назву");
        }
        let fields = document.getElementsByClassName("text_field_num");
        fields.forEach(field => {
            if(field.value == ""){
                submit = false;
                show_error(field, "Невірне значення");
            }
        });
        if (submit) {
            form.submit();
        }

    });
    $("form").on("change",".text_field_num", function () {
        if (this.value != ""){
            hide_error(this);
        } else {
            show_error(this);
        }
    });

    $("#food_name, #food_food_groups_attributes_0_group_id ").on("change", function () {
       if (this.value != ""){
           hide_error(this);
       } else {
           show_error(this);
       }
    });

    $("#upload_food_img").change(function (event) {
        readURL(this);
        showSave();
    });

    $("input.food_elements_search").on('click', function(){
        $("input.food_elements_search").not(this).val("");
        $("input.food_elements_search").not(this).parent()
            .find(".remove_img").addClass("hide");
        search_element(this);
    });

    $("input.food_elements_search").on('keyup', function(){
        search_element(this);
        $(".remove_img").removeClass("hide");
    });

    $(".food_elements_search_wrapper").on("click", ".remove_img", function(){
        $("#element_search").val("");
        $(this).addClass("hide");
        hideAllSearch();
    });

    $(".edit_food_recipe").on('click',  function(event) {
        let food_id = $(this).data("food");
        location.href = "/foods/"+food_id+"/edit";
    });     

     $(".food_elements_search_box-result").on('click', ".adding", function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();

        var element_id = null;
        hideAllSearch();

        var this_el = $(this).closest(".food_elements_search_box-result")[0],
        element_id = $(this).data("element-id");
        let element_name = $(this).data("element-name");
        let element_measure = $(this).data("element-measure");
        $(".add_fields")[0].click();
        $(".record_title.last").text(element_name);
        $(".text_field_add.last").text(element_measure);
        $(".text_field_num.last").val(element_id);
        document.querySelector(".remove_element.last").setAttribute("data-id", element_id);
        $(".record_title.last").removeClass("last");
        $(".text_field_num.last").removeClass("last");
        $(".text_field_add.last").removeClass("last");
        $(".remove_element.last").removeClass("last");
        $(".new_edit").last().focus();
        showSave();
    });

    $("form").on('click', ".remove_element",  function(event) {
        if ($(this).hasClass("dynamic")) {
            $(this).parent(".form_field").remove();
        } else {
            let el_for_remove = $(this).parent(".form_field");
            el_for_remove.hide();
            showSave();
        }
    });


    $(".text_field_num").on('keydown', function(event) {
        if (((event.which != 46 || (event.which == 46 && $(this).val() == '')) ||
            $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
           // event.preventDefault();
        }
    }).on('paste', function(event) {
      //  event.preventDefault();
    });

    $(function(){
      $('form').find(':input').each(function(i, elem) {
          let input = $(elem);
          input.data('initialState', input.val());
          let check = false;
          if ($("#food_is_public").checked) {
              check = true;
          }
          sessionStorage.setItem('is_public', check);
      });

    });

    function restore() {
        $('form').find(':input').each(function(i, elem) {
          var input = $(elem);
          input.val(input.data('initialState'));
        });
        $("#food_is_public").prop( "checked", sessionStorage.is_public);
    }
    $("#restore_btn").on('click', function(event){
        restore();
        $("#delete_btn").css("display","block");
        $("#back_btn").css("display","block");
        $("#change_btn").addClass('hide');
        $("#restore_btn").css("display","none");

    });
    $(".text_field , .text_field_num, .check_box").on('change',function(){
        showSave();
    });


});

function hideAllSearch(){
    $("#element_search_result").removeClass("show");
}
function readURL(input) {
    if (input.files && input.files[0])  {
        var reader = new FileReader();
        reader.onload = function (e) {
            var $div = $("<img>", { "class": "food_img","id": "food_img"});
            $(".food_img_def").append($div);
            document.querySelector('.food_img').setAttribute('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
    }
}

function showSave(){
    $("#delete_btn").css("display","none");
    $("#back_btn").css("display","none");
    
    $("#change_btn").removeClass('hide');
    $("#restore_btn").css("display","block");
}

function search_element(element){
    let $result = $(element).next(".food_elements_search_box-result")[0];
    let elements = $(".remove_element.dynamic");
    let food_id = null;
    let el_ids = null;
    if(window.location.pathname.includes("edit")) {
        food_id = document.getElementsByClassName('food')[0].id.match(/\d/g).join("");
    }
    el_ids = $(elements).map(function () {
        let e = $(this).data('id');
        return String(e).match(/\d/g).join("");
    }).get();

    var search = $(element).val();

    $result.classList.add("show");
    $(this).parent().find(".remove_img").removeClass("hide");

    $.ajax({
        type: "POST",
        url: "/foods/search_element",
        data: {'search': search, "el_ids": el_ids, "food_id": food_id},
        success: function(data){
            $result.innerHTML = data;
            if(data != ''){
                $result.fadeIn = '';
            } else {
                $result.fadeOut = 100;
            }
        }
    });

}

function show_error(el, text){
    if (text == null) { text = "Невірне значення";}
    el.style.borderColor = "#FF4D4F";
    $(el).parent().find(".error")[0].style.display = "block";
    $(el).parent().find(".error")[0].innerHTML = text;
}

function hide_error(el){
    el.style.borderColor = "#39B89A";
    $(el).parent().find(".error")[0].innerHTML = "";
    $(el).parent().find(".error")[0].style.display = "none";
}

window.onclick = function(event) {
    if (!event.target.closest('#element_search')) {
        hideAllSearch();
    }
}

