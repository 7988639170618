import { Tabs } from 'antd';
import React from "react";
import Login from "./Login";
import SignUp from "./SignUp";
import '../src/login.scss'


const { TabPane } = Tabs;

class LoginTab extends React.Component {
    render() {
        return (
            <Tabs defaultActiveKey="1" >
                <TabPane tab="Вхід" key="1" >
                    <Login authenticityToken={this.props.authenticityToken}></Login>
                </TabPane>
                <TabPane tab="Реєстрація" key="2"  >
                    <SignUp></SignUp>
                </TabPane>
            </Tabs>
        );
    }
}
export default LoginTab;