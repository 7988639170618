require('jquery');
import Rails from 'rails-ujs';
$(document).ready(function() {
    //custom dropdown
    const selectWrapper = document.querySelector('.select-wrapper_food');
    if (selectWrapper) {
        selectWrapper.addEventListener('click', function () {
            this.querySelector('.select').classList.toggle('open');
        })

        for (const option of document.querySelectorAll(".custom-option_food")) {
            option.addEventListener('click', function () {
                if (!this.classList.contains('selected')) {
                    this.parentNode.querySelector('.custom-option_food.selected').classList.remove('selected');
                    this.classList.add('selected');
                    this.closest('.select').querySelector('.select__trigger span').textContent = this.textContent;
                    let f_id = $(this).data("food");
                    let value_measure = $(this).data("value");
                    let data = "food_id=" + f_id + "&value=" + value_measure;
                    let url = "/foods/get_measure_food";
                    Rails.ajax({
                        type: "POST",
                        url: url,
                        contentType: "json",
                        data: data,
                        success: function (data) {
                            let bgv = $(".bgv_flex_wrapper")[0];
                            let user = data.user;
                            update_bgv(bgv, data.cbgv_p[0], data.cbgv_p, user);

                            $('.nutricion_details').children().remove();

                            jQuery.each(data.elements_p, function (index, element) {
                                if (element[3] > 0.5 && element[2] > 0.5) {
                                    var $item = $("<div>", {"class": "nutricion_details_item"});
                                    $(".nutricion_details").append($item);
                                    var $name = $("<div>", {"class": "nutricion_details_name"});
                                    $item.append($name);
                                    $name.text(element[0]);
                                    var $value = $("<div>", {"class": "nutricion_details_value"});
                                    $item.append($value);
                                    $value.text(Math.round(element[2]) + "" + element[1] + " / " +
                                        (element[3]).toFixed(2) + "%");
                                }
                            });

                        },
                        error: function () {
                            console.log("error");
                        }
                    });
                }
            })
        }

        window.addEventListener('click', function (e) {
            const select = document.querySelector('.select')
            if (!select.contains(e.target)) {
                select.classList.remove('open');
            }
        });
    }
    $("input.food_search").on('click', function(){
        $("input.food_search").not(this).val("");
        $("input.food_search").not(this).parent()
            .find(".remove_img").addClass("hide");
    });
    var delayTimer = null;
    $("input.food_search").on('keyup', function(){
        var el = $(this);
        var $result = el.next(".food_box-result")[0];
        var search = el.val();
        $result.innerHTML = "";
        if(search == ""){
            hideFilter();

        }
        if ((search != '') && (search.length >= 1)) {
            $result.classList.add("show");
            $(this).parent().find(".remove_img").removeClass("hide");
            $.ajax({
                type: "POST",
                url: "/foods/search",
                data: {'search': search, 'count': "10"},
                success: function (data) {
                    $result.innerHTML = data;
                    if (data != '') {
                        $result.fadeIn = '';
                    } else {
                        $result.fadeOut = 100;
                    }
                }
            });
        }
    });

    $(".food_box-result").on('click', ".adding", function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        $(".food_box-result").removeClass('show');
        $(".food_box-result").addClass('hide');
        let food_id = $(this).data("food");
        let sh_list_id = $(".food_box-result").data("shlist");
        $.ajax({
            type: "POST",
            url: "shopping_lists/add_to",
            data: {'food_id': food_id, 'sh_list_id': sh_list_id},
            success: function (data) {
            let el = $(".group_holder")[0];
                $(el).prepend(data.html);
                $(".remove_img").addClass("hide");
                $(".food_search").val("");
            }
        });

    });
    $(".foods_search_wrapper").on("click", ".remove_img", function(){
        hideFilter();

    });


});
function update_bgv(bgv,f_kcal , obj, user) {
    let kcal = Math.round(f_kcal * 100 / user.kcal);
    if (kcal > 100) {
        $(bgv).find(".circle")[0].setAttribute("stroke-dasharray", kcal - 100 + " 100");
        if (kcal > 110) {
            $(bgv).find(".circle")[0].classList.remove('circle_orange');
            $(bgv).find(".circle-bg")[0].classList.remove('circle_orange_full');
            $(bgv).find(".circle")[0].classList.add('circle_red');
            $(bgv).find(".circle-bg")[0].classList.add('circle_full');

        } else {
            $(bgv).find(".circle")[0].classList.remove('circle_red');
            $(bgv).find(".circle-bg")[0].classList.remove('circle_full');

            $(bgv).find(".circle")[0].classList.add('circle_orange');
            $(bgv).find(".circle-bg")[0].classList.add('circle_orange_full');
        }
    } else {
        $(bgv).find(".circle")[0].classList.remove('circle_red');
        $(bgv).find(".circle-bg")[0].classList.remove('circle_full');
        $(bgv).find(".circle")[0].classList.remove('circle_orange');
        $(bgv).find(".circle-bg")[0].classList.remove('circle_orange_full');
        $(bgv).find(".circle")[0].setAttribute("stroke-dasharray", kcal + " 100");
    }
    $(bgv).find(".percentage")[0].innerHTML = Math.round(f_kcal);

    let bgv_array = ['protein', 'fat', 'carb'];
    bgv_array.forEach((el, i) => {
        let percent = 0;
        let percent_full = 0;
        switch(el) {
            case 'protein':
                percent = Math.round(obj[1] * 100 / user.protein);
                break;
            case 'fat':
                percent = Math.round(obj[2] * 100 / user.fat);
                break;
            default:
                percent = Math.round(obj[3] * 100 / user.carb);
        }
        if (percent > 200) {
            percent_full = 200;
        }else {
            percent_full = percent;
        }
        if (percent > 100) {
            if (percent > 110) {
                $(bgv).find(".progressbar")[i].classList.remove('bar_full_orange');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_orange');
                $(bgv).find(".progressbar")[i].classList.add('bar_full');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.add('bar_red');
            } else {
                $(bgv).find(".progressbar")[i].classList.remove('bar_full');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_red');
                $(bgv).find(".progressbar")[i].classList.add('bar_full_orange');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.add('bar_orange');
            }
            $(bgv).find(".progressbar")[i].firstElementChild.style.width = percent_full - 100 + "%";
        } else {
            $(bgv).find(".progressbar")[i].classList.remove('bar_full');
            $(bgv).find(".progressbar")[i].classList.remove('bar_full_orange');
            $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_red');
            $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_orange');
            $(bgv).find(".progressbar")[i].firstElementChild.style.width = percent_full + "%";
        }
        $(bgv).find(".bgv_item")[i].lastElementChild.innerHTML = percent + "%";
    });

}
$(document).on("click", function(event){
    let $trigger = $(".filter_search");
    if($trigger !== event.target && !$trigger.has(event.target).length){
        hideFilter();
    }
});
$(document).on("click", function(event){
    let $trigger = $(".food_search");
    if($trigger !== event.target && !$trigger.has(event.target).length){
        hideFilter();
    }
});

function hideFilter(){
    const searchResults = document.querySelectorAll(".foods_search_wrapper");
    searchResults.forEach(search => {
        search.querySelector(".text_field").value = "";
        search.querySelector(".food_box-result")?.classList.remove("show");
        $(".remove_img").addClass("hide");
    });
}



