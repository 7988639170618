require('jquery');

const NUMBER_OF_SEARCH_FOODS = 8;
$(document).ready(function() {
    $("#upload_food_img").change(function () {
        console.log("ok");
        readURL(this);
        showSave();
    });

    $(".new_recipe_btn").on('click', function (event) {
        if ($(this).hasClass("clicked")) return false;

        $(this).addClass("clicked");
        let form = document.getElementById("new_recipe");
        if (window.location.pathname.includes("edit")) {
            form = document.getElementsByClassName("recipe")[0];
        }

        let submit = true;
        let title = document.getElementById("recipe_title");

        if (title.value == "") {
            submit = false;
            show_error(title, "Вкажіть назву");
        }
        let fields = document.getElementsByClassName("text_field_num");
        fields.forEach(field => {
            if (field.value == "") {
                submit = false;
                show_error(field, "Невірне значення");
            }
        });
        if (submit) {
            form.submit();
        } else{
            $(this).removeClass("clicked");
        }

    });

    $("form").on("input", ".text_field_num", function () {
        if (this.value != "") {
            hide_error(this);
        } else {
            show_error(this);
        }
    });

    $("#recipe_title").on("input", function () {
        if (this.value != "") {
            hide_error(this);
        } else {
            show_error(this);
        }
    });


    $("input.recipe_foods_search").bind('click keyup', function () {
        let $result = $(this).next(".recipe_foods_box-result")[0];

        let search = $(this).val();
        $result.classList.add("show");
        $(this).parent().find(".remove_img").removeClass("hide");

        $.ajax({
            type: "POST",
            url: "/foods/search",
            data: {'search': search, 'count': NUMBER_OF_SEARCH_FOODS},
            success: function (data) {
                $(".adding").remove();
                $result.innerHTML = $result.innerHTML + data;
                if (data != '') {
                    $result.fadeIn = '';
                } else {
                    $result.fadeOut = 100;
                }
            }
        });
    });

    $(".recipe_foods_search_wrapper").on("click", ".remove_img", function () {
        $(this).parent().find("input.recipe_foods_search").val("");
        $(this).addClass("hide");
        $(".recipe_foods_box-result").removeClass("show");
    });


    $("input.recipe_foods_search_wrapper").on('click', function () {
        $("input.recipe_foods_search_wrapper").not(this).val("");
        $("input.recipe_foods_search_wrapper").not(this).parent()
            .find(".remove_img").addClass("hide");
        $(".recipe_foods_box-result").removeClass("show");
    });

    $(".recipe_foods_box-result").on('click', ".adding", function (event) {
        event.stopPropagation();
        event.stopImmediatePropagation();

        hideAllSearch();
        let food_id = $(this).data("food");
        let food_name = $(this).data("food-name");

        $.ajax({
            type: "POST",
            url: "/foods/get_food_measures",
            data: {'food_id': food_id},
            success: function (data) {
                let measures = data.measures;
                let select_o = $(".select_measure.last").find("select > option");
                let select = $(".select_measure.last").find("select");
                $(select_o).each(function () {
                    this.remove();
                });
                $(measures).each(function () {
                    $(select).append("<option value=" + this.id + ">" + this.measure + "</option>");
                });
                $(".select_measure.last").removeClass("last");
            }
        });

        $("#recipe_foods").find(".add_fields")[0].click();
        $(".ingredient_name.last").text(food_name);
        $(".text_field_num.last").val(food_id);
        document.querySelector(".remove_food.last").setAttribute("data-id", food_id);
        $(".ingredient_name.last").removeClass("last");
        $(".text_field_num.last").removeClass("last");
        $(".remove_element.last").removeClass("last");
        $(".new_edit").last().focus();
        let el_img = $(this).find(".meal_img_i_min");
        if (el_img.length != 0) {
            let src_food = $(el_img)[0].src;

            let div = $("<img>", {"class": "meal_img_i_min"});
            $(div).attr("src",src_food).width(40).height(40);
            $(".meal_img_min.last").append(div);
        } else {
            let div = $("<div>", {"class": "meal_img_def_min"});
            $(".meal_img_min.last").append(div);
        }
        $(".meal_img_min.last").removeClass("last");
        $(".select_measure").removeClass("hide");
        $(".form_field.last").removeClass("hide");
        $(".form_field.last").removeClass("last");
        $(".recipe_foods_search_wrapper").addClass('hide');
        $("input.recipe_foods_search").val("");
        showSave();
    });

    $("#steps_holder").on('click', ".add_direction", function () {
        $("#steps_holder").find(".add_fields")[0].click();
        let count = 0;
        $(".step").each(function(){
            if($(this).parent().css("display") == "block"){
                count = count + 1;
            }
        });

        $(".step_number.last").text($(".step_number.last").text() + " " + count);
        $(".text_field.last").focus();
        $(".text_field_num.last").val(count);
        $(".step_number.last").removeClass("last");
        $(".text_field_num.last").removeClass("last");
        $(".text_field.last").removeClass("last");
        showSave();
    });

    $("form").on('click', ".remove_food", function () {
        if ($(this).hasClass("dynamic")) {
            $(this).parent().parent().remove();
        } else {
            let el_for_remove = $(this).parent().parent();
            el_for_remove.hide();
            showSave();
        }
    });
    $("form").on('click', ".remove_step", function () {
        if ($(this).hasClass("dynamic")) {
            $(this).parent(".form_field").remove();
        } else {
            let el_for_remove = $(this).parent(".form_field");
            el_for_remove.hide();
            showSave();
        }
        let i  = 1;
        $(".step_number").each(function() {
          if(  $(this).parent().css('display') == "block" ) {
              $(this).text("Крок " + i);
              i = i + 1 ;
          }
        });
    });


    $(".recipe_foods_search_wrapper").on("click", ".remove_img", function () {
        $('.recipe_foods_search_wrapper').find("input.recipe_foods_search_wrapper").val("");
        $(this).addClass("hide");
    });


    $(".text_field_num").keypress(function (event) {
        if (((event.which != 46 || (event.which == 46 && $(this).val() == '')) ||
            $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
            //event.preventDefault();
        }
    }).on('paste', function (event) {
       // event.preventDefault();
    });

    $(function () {
        $('form').find(':input').each(function (i, elem) {
            let input = $(elem);
            input.data('initialState', input.val());
            let check = false;
            if ($("#recipe_is_public").checked) {
                check = true;
            }
            sessionStorage.setItem('is_public', check);
        });

    });

    function restore() {
        $('form').find(':input').each(function (i, elem) {
            let input = $(elem);
            input.val(input.data('initialState'));
        });
        $("#recipe_is_public").prop("checked", sessionStorage.is_public);
    }

    $("#restore_btn").on('click', function (event) {
        restore();
        $("#delete_btn").css("display", "block");
        $("#back_btn").css("display", "block");
        $("#change_recipe_btn").addClass('hide');
        $("#restore_btn").css("display", "none");

    });
    $(".text_field , .text_field_num, .check_box, .input_measure").on('input', function () {
        showSave();
    });


    $(".recipe_foods_box-result").on('click', '.new_recipe_add_food', function () {
        window.open("/foods/new", '_blank');
    });

    $(".tags_wrapper").on('keyup', "input.tag_search", function (e) {
        let text = $(this).val();
        $("#tags_result").find(".tde_holder").hide();
        let count_matches = 0;
        $(".tag_title_search").each(function(){
            if($(this).text().toUpperCase().indexOf(text.toUpperCase()) != -1){
                count_matches += 1;
                $(this).parent().show();
            }
        });
        if (count_matches == 0 ){
            $(".tag_title_search_temp").text(text);
            $(".tag_title_search_temp").parent().show();
        }
    });

    $(".tde_box-result").on("click",'.tag_title_search', function(){
        tde_select(this, "tag");
        $(".tag_title_search").parent().show();
    });
    $(".tde_box-result").on("click",'.diet_title_search', function(){
        tde_select(this, "diet");
    });
    $(".tde_box-result").on("click",'.equip_title_search', function(){
        tde_select(this, "equip");
    });

    $(".tde_box-result").on("click",'.tag_title_search_temp', function(){
        let text = $('.tag_title_search_temp').text();
        let tag_id = -1;
        let el = this;
        $.ajax({
            type: "POST",
            url: "/tags/create_tag",
            data: {'name': text},
            success: function (data) {
                tag_id = data.id;
                add_tde_to_recipe(tag_id, text, "tag");
                $('.tag_title_search').parent().show();

                let tag_title_search = $("<div>", {"class": "tag_title_search",  "data-id": tag_id}).text(text);
                let tde_holder =  $("<div>", {"class": "tde_holder"}).append(tag_title_search);
                $(el).parent().parent().prepend(tde_holder);
                $(".tag_search").val("");
                $(".tag_title_search_temp").parent().hide();
                $(".tag_title_search_temp").text("");
                tde_holder.addClass("checked");
            }
        });
        $("input.tag_search").val("");
        showSave();

    });
    $(".text_field_tde").on('click', function (e) {
        $(".tde_box-result").removeClass('show');
        $(this).parent().find(".tde_box-result").addClass('show');
        $(this).find("input.tag_search").focus();
    });

    $(".tags_wrapper").on("click",".remove_tag_img", function(e){
        remove_tde($(this),"tag");
    });
    $(".diets_wrapper").on("click",".remove_tag_img", function(e){
        remove_tde($(this),"diet");
    });
    $(".equips_wrapper").on("click",".remove_tag_img", function(e){
        remove_tde($(this),"equip");
    });

    $("textarea").each(function () {
        this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
    }).on("input", function () {
        onInput();
    });

    $('.recipe_description').on('keydown', 'textarea', function(e) {
        let thisHeight = 40;
        if (e.which === 13 || e.which === 10) {
            this.value = this.value + "\n";
        }
            this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
            this.addEventListener("input", onInput, false);

    });
    $(".new_recipe_add_ingredient").on('click', function() {
       $('.recipe_foods_search_wrapper').removeClass('hide');
       $('.recipe_foods_search_wrapper').removeClass('hide');
    });

});


function onInput() {
    this.style.height = "auto";
    this.style.height = (this.scrollHeight) + "px";
}
function remove_tde(el, tde){
    let this_id = $(el).parent().data("id");
    $("."+tde+"_title_search").each(function () {
        let tde_id = $(this).data("id");
        if (this_id == tde_id) {
            $(this).parent().removeClass("checked");
            $("#"+tde+"_" + tde_id).remove();
            return false;
        }
    });
    if ($(el).hasClass("dynamic")) {
        $(el).parent().remove();
    } else {
        $(el).parent().hide();
        showSave();
    }

}

function hideAllSearch(){
    $("#recipe_search_result").removeClass("show");
}

function readURL(input) {
    if (input.files && input.files[0])  {
        let reader = new FileReader();
        reader.onload = function (e) {
            let div = $("<img>", { "class": "food_img","id": "food_img"});
            $(".food_img_def").append(div);
            document.querySelector('.food_img').setAttribute('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
    }
}

function show_error(el, text){
    if (text == null) { text = "Невірне значення";}
    el.style.borderColor = "#FF4D4F";
    $(el).parent().find(".error")[0].style.display = "block";
    $(el).parent().find(".error")[0].innerHTML = text;
    $(el).parent()[0].scrollIntoView({ behavior: 'smooth'});
}

function hide_error(el){
    el.style.borderColor = "#39B89A";
    $(el).parent().find(".error")[0].innerHTML = "";
    $(el).parent().find(".error")[0].style.display = "none";
}

function showSave(){
    $("#delete_btn").css("display","none");
    $("#back_btn").css("display","none");

    $("#change_recipe_btn").removeClass('hide');
    $("#restore_btn").css("display","block");
}
function tde_select(el, tde){
    if ( $(el).parent().hasClass("checked")){
        $(el).parent().removeClass("checked");
        let this_id = $(el).data("id");
        let tde_id = "";
        $('.'+tde+"s").each(function() {
            tde_id = $(this).data("id");
            if (this_id  == tde_id ) {
                $(this).find(".remove_tag_img")[0].click();
              //  if ($(this).hasClass("dynamic")) {
              //      $(this).remove();
             //   } else {
             //       $(this).hide();
             //   }
               return false;
            }
        });
    } else {
        $(el).parent().addClass("checked");
        let text = el.innerHTML;
        let tde_id = $(el).data("id");
        add_tde_to_recipe(tde_id, text,tde);
    }
    $("input."+tde+"_search").val("");
    showSave();
}

function add_tde_to_recipe(tde_id, text, tde) {
    let innerText = $("<div>", {"class": tde+"_title"}).text(text);
    $("."+tde+"s_wrapper").append($("<div>", { "class": tde+"s", "data-id": tde_id}).append(innerText).append($("<a>", { "class": "remove_tag_img remove_fields dynamic"})));

    $("."+tde+"s_wrapper").parent().parent().find(".add_fields")[0].click();
    $(".new_"+tde+".last").attr('id',tde+"_"+tde_id);
    $(".new_"+tde+".last").removeClass("last");
    $("."+tde+"_title_search.last").val(tde_id);
    $("."+tde+"_title_search.last").removeClass("last");

}
function calcHeight(value) {
    let numberOfLineBreaks = (value.match(/\n/g) || []).length;

    let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2;
    return newHeight;
}



$(document).on("click", function(event){
    let $trigger = $(".search_filter_wrapper");
    if($trigger !== event.target && !$trigger.has(event.target).length){
        $(".tde_box-result").removeClass('show');
    }
    $trigger = $(".recipe_foods_search_wrapper");
    if($trigger !== event.target && !$trigger.has(event.target).length){
        $(".recipe_foods_box-result").removeClass('show');
    }
});