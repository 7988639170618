require('jquery');
import Rails from 'rails-ujs';
const categoriesNames = ["Сніданок", "Обід", "Вечеря"];
$(document).ready(function() {
    setCategories();
    $(".meal_heart").on('click', function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        let r_id = $(this).data("recipe");
        let recipe_id = "recipe="+ r_id;
        let url = "/recipes/like_recipe";
        let isLiked = false;
        let heart = $(this);
        if ($(heart).find(".recipe_bookmark").hasClass("liked")){
                url = "/recipes/unlike_recipe";
                isLiked = true;
        }

        Rails.ajax({
            type: "POST",
            url: url,
            contentType: "json",
            data:  recipe_id,
            success: function() {
                    (isLiked) ? $(heart).find(".recipe_bookmark").removeClass("liked")
                        : $(heart).find(".recipe_bookmark").addClass("liked");
                    },
            error: function(){console.log("error");}
        });
    });
    $(".main_holder_liked").on('click', '.meal_bookmark_r', function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        let r_id = $(this).data("recipe");
        let recipe_holder = $(this).parent().parent();
        let recipe_id = "recipe="+ r_id;
        let url = "/recipes/like_recipe";
        let isLiked = false;
        let heart = $(this);
        if ($(heart).find(".gg-bookmark").hasClass("liked")){
            url = "/recipes/unlike_recipe";
            isLiked = true;
        }

        Rails.ajax({
            type: "POST",
            url: url,
            contentType: "json",
            data:  recipe_id,
            success: function(data) {
                if (isLiked) {
                    $(heart).find(".gg-bookmark").removeClass("liked");
                    let count = $(".count_recipes")[2].innerHTML;
                    $(".count_recipes")[2].innerHTML = parseInt(count) - 1;
                    let recipe_holders = $("#liked_recipes").find(".recipe_holder");

                    $.each($(recipe_holders), function(index,r_holder) {
                        if ($(r_holder).data("id") == r_id) {
                            $(r_holder).remove();
                        }
                    });
                    $.each($(".meal_bookmark_r"), function(index, bookmark) {
                        if ($(bookmark).data("recipe") == r_id) {
                            $(bookmark).find(".gg-bookmark").removeClass("liked");
                        }
                    });
                } else {
                    $(heart).find(".gg-bookmark").addClass("liked");
                    let count = $(".count_recipes")[2].innerHTML;
                    $(".count_recipes")[2].innerHTML = parseInt(count) + 1
                    $("#liked_recipes").append(data.html);
                }
            },
            error: function(){console.log("error");}
        });
    });

    $('.recipe_expand_btn').on("click", function(e){
        const currentBtn = $(this);
        currentBtn.hasClass("gg-chevron-right-o")?
            openDescription(currentBtn): closeDescription(currentBtn);
    });

    //custom dropdown
    const selectWrapper = document.querySelector('.select-wrapper');
    if (selectWrapper) {
        selectWrapper.addEventListener('click', function() {
            this.querySelector('.select').classList.toggle('open');
        })

        for (const option of document.querySelectorAll(".custom-option")) {
            option.addEventListener('click', function() {
                if (!this.classList.contains('selected')) {
                    this.parentNode.querySelector('.custom-option.selected').classList.remove('selected');
                    this.classList.add('selected');
                    this.closest('.select').querySelector('.select__trigger span').textContent = this.textContent;
                    let r_id = $(".meal_heart").data("recipe");
                    let value_portion = $(this).data("value");
                    let data = "recipe_id="+ r_id +"&value="+value_portion;
                    let url = "/recipes/get_portion_recipe";
                    Rails.ajax({
                        type: "POST",
                        url: url,
                        contentType: "json",
                        data:  data,
                        success: function(data) {
                            let bgv = $(".bgv_flex_wrapper")[0];
                            let user = data.user;
                            update_bgv(bgv, data.kcal_p, data.bgv_p, user);

                            $('.nutricion_details').children().remove();

                            jQuery.each(data.elements_p, function (name, element) {
                                if (element[3] > 1 && element[2] >1) {
                                    var $item = $("<div>", { "class": "nutricion_details_item"});
                                    $(".nutricion_details").append($item);
                                    var $name = $("<div>", { "class": "nutricion_details_name"});
                                    $item.append($name);
                                    $name.text(element[0]);
                                    var $value = $("<div>", { "class": "nutricion_details_value"});
                                    $item.append($value);
                                    $value.text(Math.round(element[3]) + "" + element[1] + " / " +
                                        Math.round(element[2]) + "%");
                                }
                            });
                            jQuery.each(data.foods_p, function (i, food) {
                                let el_food =$(".ingredient_amount")[i]
                                $(el_food).text(food.value+" "+food.measure)
                                let el_silver_food =$(".ingredient_amount_silver")[i]
                                let value = $(".silver_value")[i]
                                $(el_silver_food).text(Math.round(data.foods_grams*parseInt($(value).text()))+"г")
                            });


                        },
                        error: function(){console.log("error");}
                    });
                }
            })
        }

        window.addEventListener('click', function(e) {
            const select = document.querySelector('.select')
            if (!select.contains(e.target)) {
                select.classList.remove('open');
            }
        });
    }
    $("btn_add_recipe").on('click', function(e){

    });

    $("input.filter_search").on('keyup', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var el = $(this);
            var $result = el.next(".filter_box-result")[0];
            var search = el.val();
            $result.innerHTML = "";
            if(search === ""){
                hideFilter();
            }
            if ((search != '') && (search.length >= 1)){
                $result.classList.add("show");
                $(".remove_img").removeClass("hide");
                $.ajax({
                    type: "POST",
                    url: "/recipes/filter_search",
                    data: {'search': search},
                    success: function(data){
                        $result.innerHTML = data;
                        if(data != ''){
                            $result.fadeIn = '';
                        } else {
                            $result.fadeOut = 100;
                        }
                    }
                });
            }
    });
    $(".filter_box-result").on("click",'.tag_title_search', function(e){
        let text = $("<div>", {"class": "tag_title_search"}).text(this.innerHTML)
        $(".tags_wrapper_search").append($("<div>", { "class": "tags", "data-table": $(this).data("table"), "data-id": $(this).data("id")}).append(text).append($("<div>", { "class": "remove_tag_img", "data-table": $(this).data("table")})));
        $("#recipes").children(".recipe_holder").remove();
        $("#paginate_wrap").remove();
        $(".remove_img").addClass("hide");
        sendTags();
    });
    $(".remove_img").on("click", function(e){
        hideFilter();
    });
    $(".tags_wrapper_search").on("click",".remove_tag_img", function(e){
            $(this).parent().remove();
            sendTags();
    });


    $(".edit_recipe").on('click',  function(event) {
        let recipe_id = $(this).data("recipe");
        location.href = "/recipes/"+recipe_id+"/edit";
    });

    $(".tab").on('click', function(event) {
        let data_tab = $(this).data("tab");
        $(".tab").removeClass("active_tab");
        $(this).addClass("active_tab");
        $(".count_recipes").removeClass("count_active");
        $(this).find(".count_recipes").addClass("count_active");
        $(".recipes_wrapper").addClass("hide");
        $("#"+data_tab).removeClass("hide");
        if (data_tab != "recipes") {
            $(".search_filter_wrapper").addClass("hide");
        } else {
            $(".search_filter_wrapper").removeClass("hide");
        }

    });
});
function remove_tde(el, tde){

        let this_id = $(el).parent().data("id");
        $("."+tde+"_title_search").each(function () {
            let tde_id = $(this).data("id");
            if (this_id == tde_id) {
                $(this).parent().removeClass("checked");
                $("#"+tde+"_" + tde_id).remove();
                return false;
            }
        });
    if (window.location.href.includes("recipes/new")) {
        $(el).parent().remove();
    } else {
        // $(this).parent().next("input").remove();
        $(el).parent().hide();
    }

}

function sendTags(){
    let table = "";
    let tag_id = "";
    let tagsData = {};
    hideFilter();
    $.each($(".tags"), function(index, tag) {
        table = $(tag).data("table");
        tag_id = $(tag).data("id");
        if (tagsData[table]) {
            tagsData[table].push(tag_id)
        } else {
            tagsData[table] = [tag_id]
        }
    });
    $.ajax({
        type: "POST",
        url: "/recipes/filter_search_result",
        data: {'search': JSON.stringify(tagsData)},
        success: function(data){
            document.getElementById("recipes").innerHTML = data;


        }
    });
}

const setCategories = () => {
    const categories = document.querySelectorAll('.recipe_category');
    categories.forEach(category => {
        const name = category.querySelector('span').innerText;
        categoriesNames.forEach((cat, id) => {
            if(cat === name) {
                const className = `recipe_category_type${id}`;
                category.classList.add(className);
            }
        });
    });
};

const openDescription = (btn) => {
    btn.removeClass("gg-chevron-right-o");
    btn.addClass("gg-chevron-down-o");
    btn.parents(".recipe_description").find(".collapseWrapper").removeClass("hide");
};

const closeDescription = (btn) => {
    btn.removeClass("gg-chevron-down-o");
    btn.addClass("gg-chevron-right-o");
    btn.parents(".recipe_description").find(".collapseWrapper").addClass("hide");
};


function hideFilter(){
    const searchResults = document.querySelectorAll(".search_filter_wrapper");
    searchResults.forEach(search => {
        search.querySelector(".text_field").value = "";
        search.querySelector(".filter_box-result")?.classList.remove("show");
        $(".remove_img").addClass("hide");
    });
}

function update_bgv(bgv, r_kcal , obj, user) {
    let kcal = Math.round(r_kcal * 100 / user.kcal);
    if (kcal > 100) {
        $(bgv).find(".circle")[0].setAttribute("stroke-dasharray", kcal - 100 + " 100");
        if (kcal > 110) {
            $(bgv).find(".circle")[0].classList.remove('circle_orange');
            $(bgv).find(".circle-bg")[0].classList.remove('circle_orange_full');
            $(bgv).find(".circle")[0].classList.add('circle_red');
            $(bgv).find(".circle-bg")[0].classList.add('circle_full');

        } else {
            $(bgv).find(".circle")[0].classList.remove('circle_red');
            $(bgv).find(".circle-bg")[0].classList.remove('circle_full');

            $(bgv).find(".circle")[0].classList.add('circle_orange');
            $(bgv).find(".circle-bg")[0].classList.add('circle_orange_full');
        }
    } else {
        $(bgv).find(".circle")[0].classList.remove('circle_red');
        $(bgv).find(".circle-bg")[0].classList.remove('circle_full');
        $(bgv).find(".circle")[0].classList.remove('circle_orange');
        $(bgv).find(".circle-bg")[0].classList.remove('circle_orange_full');
        $(bgv).find(".circle")[0].setAttribute("stroke-dasharray", kcal + " 100");
    }
    $(bgv).find(".percentage")[0].innerHTML = Math.round(r_kcal);

    let bgv_array = ['protein', 'fat', 'carb'];
    bgv_array.forEach((el, i) => {
        let percent = 0;
        let percent_full = 0;
        switch(el) {
            case 'protein':
                percent = Math.round(obj[0] * 100 / user.protein);
                break;
            case 'fat':
                percent = Math.round(obj[1] * 100 / user.fat);
                break;
            default:
                percent = Math.round(obj[2] * 100 / user.carb);
        }
        if (percent > 200) {
            percent_full = 200;
        }else {
            percent_full = percent;
        }
        if (percent > 100) {
            if (percent > 110) {
                $(bgv).find(".progressbar")[i].classList.remove('bar_full_orange');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_orange');
                $(bgv).find(".progressbar")[i].classList.add('bar_full');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.add('bar_red');
            } else {
                $(bgv).find(".progressbar")[i].classList.remove('bar_full');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_red');
                $(bgv).find(".progressbar")[i].classList.add('bar_full_orange');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.add('bar_orange');
            }
            $(bgv).find(".progressbar")[i].firstElementChild.style.width = percent_full - 100 + "%";
        } else {
            $(bgv).find(".progressbar")[i].classList.remove('bar_full');
            $(bgv).find(".progressbar")[i].classList.remove('bar_full_orange');
            $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_red');
            $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_orange');
            $(bgv).find(".progressbar")[i].firstElementChild.style.width = percent_full + "%";
        }
        $(bgv).find(".bgv_item")[i].lastElementChild.innerHTML = percent + "%";
    });

}
$(document).on("click", function(event){
    let $trigger = $(".filter_search");
    if($trigger !== event.target && !$trigger.has(event.target).length){
       if ( !window.location.href.includes("recipes/new") && !window.location.href.includes("/edit") ) {
           hideFilter();
       }
    }
});