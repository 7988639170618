
$(document).ready(function() {

    $( "#dialog" ).dialog({
        autoOpen: false,
        closeText: "",
        resizable: false,
        modal: true,
        width: 353,
        buttons: [
        ],
        open: function(event, ui) {
            $('.ui-widget-overlay').css({ opacity: '.5' });
        },
    });
    $(".cancel_btn").on('click', function() {
        $( "#dialog" ).dialog('close');
        $( "#dialog" ).addClass('hide');
    });

    $(".remove_btn").on('click', function() {
        document.getElementById("subscription_form").submit();
    });

    $(".subscrip_btn_cancel").on('click', function(e) {
        e.preventDefault();
        $( "#dialog" ).removeClass('hide');
        $( "#dialog" ).dialog('open');

        return false;
    });

    $("input[name='subscription_type_id']").on('change', function() {
        $(".subscrip_card").removeClass('chose');
        $(this).parent().parent().addClass("chose");
        if ($(this).val() == 6) {
            $(".subscrip_btn").addClass("hide");
        } else {
            $(".subscrip_btn").removeClass("hide");
        }
    });
    $(".arrow_subscription").on('click',  function (){
         if ($(this).hasClass("open")) {
             $(this).removeClass("open");
             $(this).parent().next().addClass("hide");
         } else {
             $(this).addClass("open");
             $(this).parent().next().removeClass("hide");
         }
    });
});