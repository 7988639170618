require('jquery');
$(document).ready(function() {
    $(function(){
        $('form').find(':input').each(function(i, elem) {
            var input = $(elem);
            input.data('initialState', input.val());
        });
    });
    function restore() {
        $('form').find(':input').each(function(i, elem) {
            var input = $(elem);
            input.val(input.data('initialState'));
        });
    }
    $(".text_field , .text_field_num").keyup(function(){
        showSave();
    });
    $(".text_field , .text_field_num").change(function(){
        showSave();
    });

    $("#edit_user").on('change','.text_field', function(event) {
        $("#edit_user").find("text_field")
        var is_show = true;
        $.each($("#edit_user").find(".text_field"), function (index, text_field) {
            if (text_field.value == "") {
                is_show = false;
                return false;
            }
        });
        if (is_show){
            $("#change_pass_id").css("display", "block");
        } else{
           $("#change_pass_id").css("display", "none");
        }
    });

    $("#cancel_setting").on('click', function(event){
        restore();
        $("#submit_setting").css("display","none");
        $("#cancel_setting").css("display","none");
    });
 function showSave(){
     $("#submit_setting").css("display","block");
     $("#cancel_setting").css("display","block");
 }
    $(window).keydown(function(event){
        if(event.keyCode == 13) {
            event.preventDefault();
            return false;
        }
    });

    function isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 46 || charCode > 57)) {
            return false;
        }
        return true;
    }


    $("#age_field").change(function () {
        var titles = ['рік', 'роки', 'років'];
        $("#age_field").next(".text_field_add")[0].innerHTML = plural(document.getElementById('age_field').value, titles);
    });
    $("#upload_avatar").change(function (event) {
        readURL(this);
        showSave();
    });
    $("#date_of_birth_field").change(function () {
        var titles = ['рік', 'роки', 'років']
        let date_of_birth= Date.now()
        if( this.value != "" ) {
            date_of_birth = new Date(this.value);
        }
        let dob = calculate_age(date_of_birth);
        $("#age_field").val(dob);
        $("#age_field").next(".text_field_add")[0].innerHTML = plural(document.getElementById('age_field').value, titles);
    });

});
function calculate_age(date_of_birth) {
    var diff_ms = Date.now() - date_of_birth.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
}
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            if ($("#ava").hasClass("p_avatar_def")) {
                $("#ava").remove();
                var $div = $("<img>", { "class": "p_avatar","id": "ava"});
                $(".p_avatar_holder").prepend( $div);

            }
            document.getElementById("ava").setAttribute('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
    }
}


function plural(number, titles) {
    cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}






