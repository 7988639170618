require('jquery');
import { CSS } from 'css.gg/icons/all.css';

var originalPotion = false;
$(document).ready(function() {

    if (originalPotion === false) originalPotion = $(window).width() + $(window).height();
    $('#submit').click(function() {
        var email = document.getElementById("early_bird_email")
        if( email.value == "" || !validateEmail(email)) {
            document.getElementById("error_email").style.display = "block";
            document.getElementsByClassName('form_email')[0].style.borderColor = '#FF4C4C';
            return false;
        }

    });
    $('input,textarea').focus(function() {
        $(this).data('placeholder',$(this).attr('placeholder'))
            .attr('placeholder','');

     }).blur(function() {
        $(this).attr('placeholder', $(this).data('placeholder'));

    });
    $('.login_btn').add('div.gg-log-in');
});

function validateEmail(email) {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test( email.value );
}

function onKeyboardOnOff(isOpen) {
    if (isOpen) {
        $('#footer').addClass('hide');
        $('.text_main').addClass('hide');
        $('.text_mid').addClass('hide');
        $('.text_header').addClass('hide');
    } else {
        $('#footer').removeClass('hide');
        $('.text_main').removeClass('hide');
        $('.text_mid').removeClass('hide');
        $('.text_header').removeClass('hide');
    }
}

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "winphone";
    }

    if (/android/i.test(userAgent)) {
        return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
    }

    return "";
}

function applyAfterResize() {

    if (getMobileOperatingSystem() != 'ios') {
        if (originalPotion !== false) {
            var wasWithKeyboard = $('body').hasClass('view-withKeyboard');
            var nowWithKeyboard = false;

            var diff = Math.abs(originalPotion - ($(window).width() + $(window).height()));
            if (diff > 100) nowWithKeyboard = true;

            $('body').toggleClass('view-withKeyboard', nowWithKeyboard);
            if (wasWithKeyboard != nowWithKeyboard) {
              // onKeyboardOnOff(nowWithKeyboard);
            }
        }
    }
}

$(document).on('focus blur', 'select, textarea, input[type=text], input[type=date], input[type=password], input[type=email], input[type=number]', function(e){
    var $obj = $(this);
    var nowWithKeyboard = (e.type == 'focusin');
    $('body').toggleClass('view-withKeyboard', nowWithKeyboard);
    //onKeyboardOnOff(nowWithKeyboard);
});

$(window).on('resize orientationchange', function(){
    applyAfterResize();
});

