import React from "react";
import axios from 'axios';
import '../src/login.scss';

import {Form, Input, Button} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {TextField} from "@material-ui/core";

class Login extends React.Component
{

    handleLogin(e) {

        var userInfo = {
            user: {
                email: document.getElementById('user_email').value,
                password: document.getElementById('user_password').value
            }
        }
        axios({
            url:'/users/login',
            method:'post',
            headers:
                { 'X-CSRF-TOKEN': $("meta[name='csrf-token']").attr('content') },
            data: userInfo
        }).then( res =>{
            if (res.status == 200) {
                console.log(res.status)
                location.reload()
            }
        }).catch(error => console.log(error))
        e.preventDefault()

    };

    render() {
        return (
            <Form
                name="user"
            >
                <input name="utf8" type="hidden" value="✓"/>
                <input type='hidden' name='authenticity_token' value={this.props.authenticityToken} />
                <Form.Item
                    label=""
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Будь ласка, введіть свою пошту!',
                        },
                    ]}
                >
                    <Input  prefix={<UserOutlined style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.25)' }} />} placeholder="E-mail"/>
                </Form.Item>

                <Form.Item
                    label=""
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Будь ласка, введіть пароль!',
                        },
                    ]}
                >
                    <Input.Password prefix={< LockOutlined style={{  fontSize: '16px',color: 'rgba(0, 0, 0, 0.25)' }} />} placeholder="Пароль" />
                </Form.Item>


                <Form.Item >
                    <Button type="primary"  className={"btn_form"} htmlType="submit" onClick={this.handleLogin} >
                        Увійти
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
export default Login;