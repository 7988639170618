import {menu} from "caniuse-lite/data/features";

require('jquery');
import Rails from 'rails-ujs'
Rails.start()

var week = gon.week;
var  today = gon.days_number;
var months = gon.months;
$(document).ready(function() {

    $( "#dialog" ).dialog({
        autoOpen: false,
        closeText: "",
        resizable: false,
        modal: true,
        width: 353,
        buttons: [
        ],
        open: function(event, ui) {
            $('.ui-widget-overlay').css({ opacity: '.5' });
        },
    });
    $(".cancel_btn").on('click', function() {
        $( "#dialog" ).dialog('close');
    });

    $(".save_btn").on('click', function() {
        window.location.href = "/subscriptions";
    });

    const menuLinks = document.querySelectorAll('.menu_card');
    menuLinks.forEach(link => {
        link.classList.remove('menu_card_active');
        const path = window.location.pathname;
        const linkValue = link.getAttribute("href");
        if(path === linkValue || path === linkValue+"/") {
            link.classList.add('menu_card_active');
        }
    });

    $(".open_search").on('click', function() {
        hideAllSearch();
        $(this).find('.search_wrapper').addClass("show");
        const parentEl = $(this).parents('.edit_group');
        parentEl.next('.search_wrapper').removeClass("hide");
    });

    $("input.search").on('click', function(){
        $("input.search").not(this).val("");

    });
    var delayTimer = null;
    $("input.search").on('keyup', function(){
      var el = $(this);
        if (delayTimer) {
            clearTimeout(delayTimer);
        }
        delayTimer = setTimeout(function() {
        var $result = el.next(".search_box-result")[0];
        var search = el.val();
        $result.innerHTML = "";
        //if(search === ""){
        //   el.parent().find(".close_img").addClass("hide");
       // }
        if ((search != '') && (search.length >= 1)){
            $result.classList.add("show");
           // el.parent().find(".close_img").removeClass("hide");
            $.ajax({
                type: "POST",
                url: "/recipes/search",
                data: {'search': search},
                success: function(data){

                    $result.innerHTML =  $result.innerHTML + data;
                    if(data != ''){
                        $result.fadeIn = '';
                    } else {
                        $result.fadeOut = 100;
                    }
                }
            });
            $.ajax({
                type: "POST",
                url: "/foods/search",
                data: {'search': search},
                success: function(data){

                    $result.innerHTML = $result.innerHTML + data;
                    if(data != ''){
                        $result.fadeIn = '';
                    } else {
                        $result.fadeOut = 100;
                    }
                }
            });
        } else {
            $result.innerHTML ='';
            $result.fadeOut = 100;
        }}, 1000);
    });


    $(".search_wrapper").on("click", ".close_img", function(){
        $('.search_wrapper').find("input.search").val("");
        $('.search_wrapper').addClass("hide");
    });

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    $(".eye_icon").on('click',function() {
        this.classList.toggle('visible');
        if ($("#user_password").attr('type') === 'password') {
            $("#user_password").attr('type', 'text');
            if ($('#user_password_confirmation').length > 0) {
                $("#user_password_confirmation").attr('type', 'text');
            }
        } else {
            $("#user_password").attr('type', 'password');
            if ($('#user_password_confirmation').length > 0) {
                $("#user_password_confirmation").attr('type', 'password')
            }
        }
    });

    $(".btn_day_holder").on('click', function(event){
        event.stopPropagation();
        event.stopImmediatePropagation();
        $(".today_title").text(week[this.id]+ ", "+ today[this.id] +" "+ months[this.id]);
        $(".btn_day").removeClass('active');
        $("#btn_day_"+ this.id).addClass('active');
        $(".main_holder").addClass('hide');
        $(".main_holder").removeClass('isActive');
        $("#main_holder_"+this.id.split("_").pop()).removeClass('hide');
        $("#main_holder_"+this.id.split("_").pop()).addClass('isActive');

    });

    $(".main_holder").on('click',".meal_bookmark", function(event)  {
        event.stopPropagation();
        event.stopImmediatePropagation();
        var r_id = $(this).data("recipe");
       var recipe_id = "recipe="+ r_id;
       var url = "/recipes/like_recipe";
       var isLiked = false
       if ($(this).find(".gg-bookmark").hasClass("liked")){
             url = "/recipes/unlike_recipe"
             isLiked = true
        }
        var this_heart_el = $(this);
        Rails.ajax({
            type: "POST",
            url: url,
            contentType: "json",
            data:  recipe_id,
            success: function(data) {

                $.each($(".meal_bookmark"), function(index, heart){
                    if( $(heart).data("recipe") ==  r_id  ){
                        if(isLiked) {
                            $(heart).find(".gg-bookmark").removeClass("liked");
                            $(heart).find(".meal_dd_text").text("Вподобати");
                        }
                        else {
                            $(heart).find(".gg-bookmark").addClass("liked");
                            $(heart).find(".meal_dd_text").text("Вподобано");
                        }
                    }
                });
            },
            error: function(data){console.log("error")}
        });
    });

    $(".main_holder").on('click',".meal_delete", function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        let r_id = $(this).data("recipe");
        let f_id = $(this).data("food");
        let menu_recipe_id = "recipe="+ r_id+"&food_id="+f_id;
        let url = "/home/recipe_delete";
        let el = null;
        Rails.ajax({
            type: "POST",
            url: url,
            contentType: "json",
            data:  menu_recipe_id,
            success: function(data) {
                if (f_id) {
                    el = $("#food" + f_id).parent();
                    $("#food" + f_id).remove();
                } else {
                    el = $("#" + r_id).parent();
                    $("#" + r_id).remove();
                }
                let day_index = $(el).data("day");
                let category = $(el).data("category");
                $("#cat_kcal_"+day_index+"_"+category).text(data.cat_kcal[day_index][category]+" ккал");
                let el_bgv = $(".isActive").find (".bgv_flex_wrapper");
                update_bgv(el_bgv, data.menu_day, data.user )

            },
            error: function(xhr, status, error){
                if (xhr.error == 'limit'){
                    show_dialog();
                } else { console.log(error);}
            }
        });
    });

    $(".heart_liked").on('click', function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        var r_id = $(this).data("recipe");
        var recipe_id = "recipe="+ r_id;
        var url = "/recipes/unlike_recipe";
        $(this).addClass("heart_unlike");
        $(this).removeClass("heart_liked");

        Rails.ajax({
            type: "POST",
            url: url,
            contentType: "json",
            data:  recipe_id,
            success: function(data) {
                $("#"+r_id).remove();
            },
            error: function(data){console.log("error")}
        });
    });

    $("#btn_refresh_day").on('click', function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        var day_index = $(".btn_day.active")[0].id.split("_").pop();
        var menu_id = $(".menu_plan_holder").data("menu");

        let categories = $(".isActive").find('.edit_block_refresh');
        for(let i = 0; i < categories.length; i++){
            refresh_category(day_index, $(categories[i]).data("category"), menu_id)
        }
    });

    $(".edit_block_refresh").on('click', function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        var day_index = $(this).data("day");
        var category = $(this).data("category");
        var menu_id = $(".menu_plan_holder").data("menu");
        refresh_category(day_index, category, menu_id);
    });

    $(".main_holder").on('click', ".open_details", function(event) {
        $(this).parent().parent().next(".recipe_details_holder").removeClass("hide");
        $(this).addClass("invisible");
    });


    $(".dessert_block_refresh").on('click', function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        var menu_id = $(".menu_plan_holder").data("menu");
        var data_val = "menu_id=" +menu_id
        Rails.ajax({
            type: "POST",
            url: "/home/refresh_desserts",
            contentType: "json",
            data:  data_val,
            success: function(data){
                var desserts = data.desserts;
                $(".dessert_holder").children(".dessert_item_holder").remove();
                $(".dessert_holder").append(data.html) ;
            },
            error: function(xhr, status, error){
                if (xhr.error == 'limit'){
                    show_dialog();
                } else { console.log(error);}
            }
        });
    });
    $(".search_box-result").on('click', ".adding", function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();

        var recipe_id = null;
        var food_id = null;
        hideAllSearch();
        var params = 0 ;
        var this_el = $(this).closest(".search_box-result")[0];
        var day_index = $(this_el).data("day");
        var category = $(this_el).data("category");
        var menu_id = $(".menu_plan_holder").data("menu");
        recipe_id = $(this).data("recipe");
        food_id = $(this).data("food");
        var url = "/home/add_recipe";
        var  params = "day_index="+ day_index + "&category=" + category + "&recipe=" + recipe_id+"&menu_id=" +menu_id;
        if (food_id != null){
            url = "/home/add_food";
            params = "day_index="+ day_index + "&category=" + category + "&recipe=" + recipe_id+"&menu_id=" +menu_id +"&food=" +food_id;
        }
        $("input.search").val("");
        Rails.ajax({
            type: "POST",
            url: url,
            contentType: "json",
            data:  params,
            success: function(data){
                let cat_holder = $("#category_holder_"+day_index+"_"+category);
                $(cat_holder).append(data.html) ;
                $("#cat_kcal_"+day_index+"_"+category).text(data.cat_kcal[day_index][category]+" ккал");
                let el_bgv = $(".isActive").find (".bgv_flex_wrapper");
                update_bgv(el_bgv, data.menu_day, data.user );

            },
            error: function(xhr, status, error){
                if (xhr.error == 'limit'){
                    show_dialog();
                } else { console.log(error);}
            }
        });

    });
    $(".main_holder").on('click', ".meal_refresh", function(event) {
        event.stopPropagation();
        event.stopImmediatePropagation();
        let m_recipe_id = $(this).data("recipe");
        let menu_id = $(".menu_plan_holder").data("menu");
        let recipe = "m_recipe_id="+ m_recipe_id+"&menu_id=" +menu_id
        Rails.ajax({
            type: "POST",
            url: "/home/refresh_recipe",
            contentType: "json",
            data: recipe ,
            success: function(data){
               let el = $("#" +m_recipe_id);
               let cat_holder = $(el).parent();
               let day_index = $(cat_holder).data("day");
               let category = $(cat_holder).data("category");
               $("#cat_kcal_"+day_index+"_"+category).text(data.cat_kcal[day_index][category]+" ккал");
               let nextEl = $(el)[0].nextElementSibling;
                $(el).remove();
                if ( nextEl != null) {
                    $(nextEl).before(data.html, $(nextEl));
                } else{
                    $(cat_holder).append(data.html);
                }
                let el_bgv = $(".isActive").find (".bgv_flex_wrapper");
                update_bgv(el_bgv, data.menu_day, data.user );

            },
            error: function(xhr, status, error){
                if (xhr.error == 'limit'){
                    show_dialog();
                } else { console.log(error);}
            }
        });
    });

    if(window.location.pathname == "/users/login" || window.location.pathname == "/users/sign_up"  ) {
        if ($(".ant-alert-message")[0]){
            $(".logo")[0].style.marginTop = '40px';
        }
    }

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });


    $(".menu_icon").on('click', function(event) {
        event.stopPropagation();
        document.getElementById("menu-content").classList.toggle("is_show");
    });

    $(".close").on('click', function(event) {
        document.getElementById("menu-content").classList.remove("is_show");

    });
    $(".menu_card").on('click', function(event) {
        document.getElementById("menu-content").classList.remove("is_show");
    });
    $(".main_holder").on('click',".meal_dots",  function(event) {
        hideAllDropDown();

        $(this).next(".dropdown-content")[0].classList.toggle("show");
    });


    $(".main_holder").on("change", ".serving", function (e) {
     //   $(this).parent().parent().find(".save_serving_btn").removeClass("btn_inactive");
        let serving_value = 0;
        let serving_def = $(this).parent().find(".serving_field_text").val();
        if (serving_def != "") {
            serving_def = parseInt($(this).parent().find(".serving_field_text").val());
        }
        let gram_def = parseInt($(this).data("gram"));
        if (this.value == 'portion'){
            serving_value = (serving_def/ gram_def).toFixed(2);
            $(this).parent().prev(".serving_lable").text("Порції");

        } else{
            serving_value = gram_def* serving_def;
            $(this).parent().prev(".serving_lable").text("Грами");
        }
        $(this).parent().find(".serving_field_text").val(serving_value);
    });


    $(".main_holder").on('click', '.cancel_serving_btn', function (){
        $(this).parent().parent().addClass("hide");
        $(this).parent().parent().parent().find(".open_details").removeClass("invisible");
    });

    $(".main_holder").on('click', '.save_serving_btn', function (){
     //   if (!$(this).hasClass("btn_inactive")){
           // $(this).addClass("btn_inactive");
            let m_recipe_id = $(this).data("menurecipe");
            let serving = "portion";
            let value = parseFloat($(this).parent().parent().find(".serving_field_text").val());
            if ($(this).parent().parent().find(".serving").val() == 'gram') {
                serving = "gram";
            }
            let this_btn = this;
            Rails.ajax({
                type: "POST",
                url: "/home/edit_menurecipe",
                contentType: "json",
                data: "m_recipe_id=" + m_recipe_id + "&serving=" + serving + "&value=" + value,
                success: function (data) {
                    let menu_day = data.menu_day;
                    let menu_recipe = data.menu_recipe;
                    let cal_holder = $("#cal_holder_" + menu_recipe.id);
                    let day_index = $(cal_holder).parents().eq(2).data("day");
                    let category = $(cal_holder).parents().eq(2).data("category");
                    $("#cat_kcal_"+day_index+"_"+category).text(data.cat_kcal[day_index][category]+" ккал");
                    $(cal_holder).find(".count_cal")[0].innerHTML = Math.round(menu_recipe.kcal);
                    if (menu_recipe.serving == "portion") {
                        $(cal_holder).find(".text_cal")[1].innerHTML = "Порція";
                    } else {
                        $(cal_holder).find(".text_cal")[1].innerHTML = "Вага";
                    }
                    $(cal_holder).find(".count_cal")[1].innerHTML = menu_recipe.value;
                    let user = data.user;
                    let bgv = $(this_btn).parent().parent().find(".bgv_flex_wrapper");
                    update_bgv(bgv, menu_recipe, user);
                    bgv = $(".isActive").find(".bgv_flex_wrapper");
                    update_bgv(bgv, menu_day, user);

                },
                error: function(xhr, status, error){
                    if (xhr.error == 'limit'){
                        show_dialog();
                    } else { console.log(error);}
                }

            });
     //   }
       $(this).parent().parent().addClass("hide");
       $(this).parent().parent().parent().find(".open_details").removeClass("invisible");
    });

    $(".main_holder").on('click', '.save_food_gram_btn', function (){
        let m_food_id = $(this).data("menufood");
        let value = parseInt($(this).parent().parent().find(".serving_field_text").val());
        let this_btn = this;

        Rails.ajax({
            type: "POST",
            url: "/home/edit_menufood",
            contentType: "json",
            data:  "m_food_id=" + m_food_id+ "&value="+ value,
            success: function(data){
                let menu_day = data.menu_day;
                let menu_food = data.menu_food;
                let cal_holder = $("#cal_holder_food_"+menu_food.id);
                let day_index = $(cal_holder).parents().eq(2).data("day");
                let category = $(cal_holder).parents().eq(2).data("category");
                $("#cat_kcal_"+day_index+"_"+category).text(data.cat_kcal[day_index][category]+" ккал");
                $(cal_holder).find(".count_cal")[0].innerHTML =  Math.round(menu_food.kcal);
                $(cal_holder).find(".count_cal")[1].innerHTML = menu_food.value + "г";
                let user = data.user;
                let bgv = $(this_btn).parent().parent().find (".bgv_flex_wrapper");
                update_bgv(bgv, menu_food, user);

                bgv = $(".isActive").find (".bgv_flex_wrapper");
                update_bgv(bgv, menu_day, user);
            },
            error: function(xhr, status, error){
                if (xhr.error == 'limit'){
                    show_dialog();
                } else { console.log(error);}
            }
        });
        $(this).parent().parent().addClass("hide");
        $(this).parent().parent().parent().find(".open_details").removeClass("invisible");
    });
    $(".main_dessert_holder").on('click', '.dessert_block_refresh_one', function (event){
        event.stopPropagation();
        let menu_id = $(".menu_plan_holder").data("menu");
        let dessert_id = ($(this).next(".dessert_item")[0].id).replace(/\D/g, '');
        let data_val = "menu_id=" +menu_id + "&dessert_id=" +dessert_id
        let for_remove = $(this).parent();
        Rails.ajax({
            type: "POST",
            url: "/home/refresh_desserts",
            contentType: "json",
            data:  data_val,
            success: function(data){
                $(for_remove).remove();
                $(".dessert_holder").append(data.html) ;
            },
            error: function(xhr, status, error){
                if (xhr.error == 'limit'){
                    show_dialog();
                } else { console.log(error);}
            }
        });
    });
});

function update_bgv(bgv, obj, user) {
    let kcal = Math.round(obj.kcal * 100 / user.kcal);
    if (kcal > 100) {
        $(bgv).find(".circle")[0].setAttribute("stroke-dasharray", kcal - 100 + " 100");
        if (kcal > 110) {
            $(bgv).find(".circle")[0].classList.remove('circle_orange');
            $(bgv).find(".circle-bg")[0].classList.remove('circle_orange_full');
            $(bgv).find(".circle")[0].classList.add('circle_red');
            $(bgv).find(".circle-bg")[0].classList.add('circle_full');

        } else {
            $(bgv).find(".circle")[0].classList.remove('circle_red');
            $(bgv).find(".circle-bg")[0].classList.remove('circle_full');

            $(bgv).find(".circle")[0].classList.add('circle_orange');
            $(bgv).find(".circle-bg")[0].classList.add('circle_orange_full');
        }
    } else {
        $(bgv).find(".circle")[0].classList.remove('circle_red');
        $(bgv).find(".circle-bg")[0].classList.remove('circle_full');
        $(bgv).find(".circle")[0].classList.remove('circle_orange');
        $(bgv).find(".circle-bg")[0].classList.remove('circle_orange_full');
        $(bgv).find(".circle")[0].setAttribute("stroke-dasharray", kcal + " 100");
    }
    $(bgv).find(".percentage")[0].innerHTML = Math.round(obj.kcal);

    let bgv_array = ['protein', 'fat', 'carb'];
    bgv_array.forEach((el, i) => {
        let percent = 0;
        let percent_full = 0;
        switch(el) {
            case 'protein':
                percent = Math.round(obj.protein * 100 / user.protein);
                break;
            case 'fat':
                percent = Math.round(obj.fat * 100 / user.fat);
                break;
            default:
                percent = Math.round(obj.carb * 100 / user.carb);
        }
        if (percent > 200) {
            percent_full = 200;
        }else {
            percent_full = percent;
        }
        if (percent > 100) {
            if (percent > 110) {
                $(bgv).find(".progressbar")[i].classList.remove('bar_full_orange');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_orange');
                $(bgv).find(".progressbar")[i].classList.add('bar_full');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.add('bar_red');
            } else {
                $(bgv).find(".progressbar")[i].classList.remove('bar_full');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_red');
                $(bgv).find(".progressbar")[i].classList.add('bar_full_orange');
                $(bgv).find(".progressbar")[i].firstElementChild.classList.add('bar_orange');
            }
            $(bgv).find(".progressbar")[i].firstElementChild.style.width = percent_full - 100 + "%";
        } else {
            $(bgv).find(".progressbar")[i].classList.remove('bar_full');
            $(bgv).find(".progressbar")[i].classList.remove('bar_full_orange');
            $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_red');
            $(bgv).find(".progressbar")[i].firstElementChild.classList.remove('bar_orange');
            $(bgv).find(".progressbar")[i].firstElementChild.style.width = percent_full + "%";
        }
        $(bgv).find(".bgv_item")[i].lastElementChild.innerHTML = percent + "%";
    });

}


// Close the dropdown menu if the user clicks outside of it
document.onclick = function(event) {
    if (!event.target.matches('.meal_dots')) {
        hideAllDropDown();
    }
    if (!event.target.closest('.search_wrapper') && !event.target.matches('.open_search')) {
        hideAllSearch();
    }
    if ($('#menu-content').length > 0) {
        document.getElementById("menu-content").classList.remove("is_show");
    }
}

function hideAllDropDown(){
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
        }
    }
}
function refresh_category(day_index, category, menu_id){
    let allIds = "day_index="+ day_index + "&category=" + category+"&menu_id=" +menu_id;
    Rails.ajax({
        type: "POST",
        url: "/home/refresh_category",
        contentType: "json",
        data:  allIds,
        success: function(data) {
            let cat_holder = ("#category_holder_" + day_index + "_" + category);
            $(cat_holder).prev().find(".cat_kcal").text(data.cat_kcal[day_index][category]+" ккал");
            $(cat_holder).children(".recipe_holder").remove();
            $(cat_holder).append(data.html)
            let el_bgv = $(".isActive").find(".bgv_flex_wrapper");
            update_bgv(el_bgv, data.menu_day, data.user);
        },
        error: function(xhr, status, error){
            if (xhr.error == 'limit'){
                show_dialog();
            } else { console.log(error);}
        }
    });
}

function hideAllSearch(){
    const searchResults = document.querySelectorAll(".search_wrapper");
    searchResults.forEach(search => {
        search.classList.add("hide");
        search.querySelector(".text_field").value = "";
        search.querySelector(".search_box-result")?.classList.remove("show");

    });
}

function checkImgLoad(img, src) {
    const loadedImg = img[0];
    loadedImg.classList.add('meal_img_def');
    const newImg = new Image();
    newImg.onload = function() {
        loadedImg.src = this.src;
    };
    newImg.src = src;
}

function show_dialog(){
    $( "#dialog" ).dialog('open');
    $( "#dialog" ).removeClass('hide');
}
