require('jquery');
const Rails = require("rails-ujs");

$(document).ready(function() {
    $(".main_holder_liked").on('click', ".shopping_details", function (event) {
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
            $(this).parent().parent().next().addClass("hide");
        } else {
            $(this).addClass("open");
            $(this).parent().parent().next().removeClass("hide");
        }
    });

    $(".group_block_wrapper").on('click', function () {
        if ($(this).next().hasClass("hide")) {
            $(this).find(".arrow").removeClass("gg-chevron-right-o");
            $(this).find(".arrow").addClass("gg-chevron-down-o");
            $(this).next().removeClass("hide");
        } else {
            $(this).find(".arrow").removeClass("gg-chevron-down-o");
            $(this).find(".arrow").addClass("gg-chevron-right-o");
            $(this).next().addClass("hide");
        }
    });

    $(".main_holder_liked").on('click', '.cancel_serving_btn', function (){
        $(this).parent().parent().addClass("hide");
        $(this).parent().parent().parent().find(".shopping_details").removeClass("open");
    });
    $(".main_holder_liked").on('click', '.save_serving_btn', function (){
        let listproduct_id = $(this).data("listproduct");
        let value = parseFloat($(this).parent().parent().find(".serving_field_text").val());
        let measure_id = $(this).parent().parent().find(".serving").val()
        let is_grocery = $(this).data("grocery");
        let this_btn = this;
        Rails.ajax({
            type: "POST",
            url: "shopping_lists/edit_listproduct",
            contentType: "json",
            data: "listproduct_id=" + listproduct_id + "&measure_id=" + measure_id + "&value=" + value + "&is_grocery="+is_grocery,
            success: function (data) {
                $("#product_" + listproduct_id).text( data.value + " " + data.measure);
            },
            error: function (data) {
                console.log("error")
            }

        });

        $(this).parent().parent().addClass("hide");
        $(this).parent().parent().parent().find(".shopping_details").removeClass("open");
    });

    $(".main_holder_liked").on("change", ".serving", function (e) {
        let serving_value = 0;
        let serving_def = $(this).parent().find(".serving_field_text").val();
        let gram_old =  parseFloat($(this).parent().data("grams"))
        if (serving_def != "") {
            serving_def = parseFloat($(this).parent().find(".serving_field_text").val());
        }
        let gram_def = parseFloat($('option:selected',this).data("gram"));
        let gram_def_new = gram_def
        if (gram_def == 100 ) {gram_def_new = 1}
        if (gram_old == 100 ) {gram_old = 1}

        serving_value = ((gram_old* serving_def)/gram_def_new).toFixed(2);

        $(this).parent().find(".serving_field_text").val(serving_value);

        $(this).parent().data('grams', gram_def );
    });
    $(".main_holder_liked").on('click',".ingredient_amount_silver", function(e){
        e.stopPropagation();
        $(".inrecipe_holder").addClass("hide");
        $(this).next(".inrecipe_holder").removeClass("hide");
    });

   // $(".main_holder_liked").on('mouseout',".ingredient_amount_silver", function(){
    //   $(this).next().addClass("hide");
   // });

    $(".main_holder_liked").on('click', ".gg-radio-check", function (e) {

        $(this).addClass("gg-check-o");
        $(this).removeClass("gg-radio-check");
        let el_curr = $(this).parent().parent();

        let is_grocery = $(el_curr).data("grocery");
        let product_id = $(el_curr).data("product");

        $.ajax({
            type: "POST",
            url: "shopping_lists/product_is_done",
            data: {"product_id": product_id, "is_done": true, "is_grocery": is_grocery},
            success: function (data) {
                el_curr.next().remove();
                if (el_curr.parent().children().length == 1) {
                    $(el_curr).parent().prev().hide();
                    $(el_curr).parent().hide();
                }

                el_curr.remove();
                let el = $(".group_holder_done")[0];
                $(el).prepend(data.html);
            }
        });
    });
    $(".main_holder_liked").on('click', ".gg-check-o", function (e) {
        if (!$(this).parent().hasClass("submit_day_filter")) {
            $(this).addClass("gg-radio-check");
            $(this).removeClass("gg-check-o");

            let el_curr = $(this).parent().parent();
            let product_id = $(el_curr).data("product");
            let is_grocery = $(el_curr).data("grocery");

            $.ajax({
                type: "POST",
                url: "shopping_lists/product_is_done",
                data: {'product_id': product_id, "is_done": false,  "is_grocery": is_grocery},
                success: function (data) {
                    el_curr.next().remove();
                    el_curr.remove();
                    let el = $(".group_holder")[0];
                    $(el).before(data.html);
                }
            });
        }
    });

    $(".shlist_search_wrapper").on("click", ".remove_img", function(){
        $(".food_search").val("");
        $(this).addClass("hide");
        $(".food_box-result").removeClass("show");
    });

    $(".days_filter").on("click", ".btn_day_filter", function(e) {
        e.preventDefault();
        e.stopPropagation();
        let id = $(this).data("day");
        let val = $(".submit_day_filter").find(".gg-check-o").val();
        if ($(this).hasClass("active")) {
            $(this).removeClass("active");
            val = val.replaceAll(id, '');
            $(".submit_day_filter").find(".gg-check-o").val(val);
        } else {
            if (val != "") {
                val = val + " ";
            }
            $(this).addClass("active");
            $(".submit_day_filter").find(".gg-check-o").val( val +id)
        }

        $(".submit_day_filter").find(".gg-check-o").removeAttr("disabled")
        $(".submit_day_filter").find(".gg-check-o").addClass("liked");

    });

});

$(document).on("click", function(event){

    if(!event.target.matches('.ingredient_amount_silver')){
        $(".inrecipe_holder ").addClass("hide");
    }
});
